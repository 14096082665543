
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "entityWrapper",
  props: {},
  setup() {
    const heightStyle = reactive({
      //  height: "300px"
    })
    return {
      heightStyle
    };
  },
});
