
import { defineComponent, watch, ref } from "vue";
import { RightOutlined } from "@ant-design/icons-vue";
// import { toThousands } from "@/utils/approve/index";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import useDealerPayout from "@/hooks/payment/PaymentTracking/useDealerPayout"
import { DataProps } from "./type";
export default defineComponent({
  name: "entity",
  props: {
    payoutRound: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { getData, pageData } = useDealerPayout();
    getData(props.payoutRound);
    watch(
      () => props.payoutRound,
      (newValue: string) => {
        getData(newValue);
      }
    );
    const getRowClass = (data: DataProps[]) => {
      if (!data) {
        data = []
      }
      return (record: DataProps, index: number) => {
        if (index + 1 === data.length) {
          return "table-total";
        } else {
          if (index % 2 === 1) {
            return "table-striped";
          } else {
            return null;
          }
        }
      };
    };
    const activeKey = ref(["0"]);
    return {
      pageData,
      getRowClass,
      activeKey,
      toThousandsAndTwoDecimal
    };
  },
  components: {
    RightOutlined,
  },
});
