
import { defineComponent, ref, computed } from "vue";
import EntityWrapper from "./entityWrapper.vue";
import Entity from "./entity.vue";
import { getRoundListByDate } from "@/API/payment";
export default defineComponent({
  name: "DealerSetting",
  props: {},
  setup() {
    const payoutRound = ref();
    const roundListRes = ref();
    const roundList = computed(() => {
      return roundListRes.value;
    });
    getRoundListByDate({
      params: {
        month: '0',
        year: '0',
        type: "DEALER",
      },
    }).then((res) => {
      roundListRes.value = res;
      payoutRound.value = roundListRes.value[0]
    });
    return {
      payoutRound,
      roundList
    };
  },
  components: {
    EntityWrapper,
    Entity,
  },
});
